<template>
  <teleport to="body">
    <button
      aria-label="scroll to top"
      id="scrollToTop"
      @click="scrollUp"
      class="fixed bottom-5 right-5 z-10 cursor-pointer rounded bg-neutral-grey-3 bg-opacity-50 p-2"
    >
      <img
        src="@/assets/svg/icons/icons-arrow-to-top.svg"
        alt="icono de flecha hacia arriba"
        class="h-6 w-6"
      />
    </button>
  </teleport>
</template>

<script setup>
const scrollUp = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
</script>
